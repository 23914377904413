<template>
	<div class="shoptype">
		<div class="inp">
			<i class="el-icon-search"></i>
			<input type="text" v-model="searchShop" placeholder="搜索商品" />
			<div @click="Searchname">搜索</div>
		</div>
		<div class="leftlst">
			<div class="toplst">
				<div class="alltype" v-for="(item,index) in typeList" :key="index" @click="Switchtype(index,item.id)">
					<div :class="index==titnum?'type':''">{{item.cate_name}}</div>
				</div>
			</div>
			<!-- <div class="more">
				<span><i class="el-icon-menu"></i></span>
				<span>更多分类</span>
				<div class="moreinfo">
					<div v-for="(item,index) in typeList" :key="index">
						{{item.cate_name}}
					</div>
				</div>
			</div> -->
		</div>
		<div class="rightlst">
			<div class="label">
				<div>分类：</div>
				<div>
					<div class="scrollx">
						<div :class="classnum==0?'label-color':''" @click="Switchclassification(0)">全部</div>
						<div v-for="(item,index) in typeList[titnum].children"
							:class="classnum==item.id?'label-color':''" :key="index"
							@click="Switchclassification(item.id)">
							{{item.cate_name}}
						</div>
					</div>
				</div>
			</div>
			<div class="label">
				<div>品牌：</div>
				<div>
					<div class="scrollx">
						<div :class="brandnum==0?'label-color':''" @click="Switchbrand(0)">全部</div>
						<div v-for="(item,index) in brandList" :class="brandnum==item.id?'label-color':''" :key="index"
							@click="Switchbrand(item.id)">
							{{item.brand_name}}
						</div>
					</div>
				</div>
			</div>
			<div class="sort">
				<div>排序：</div>
				<span @click="SwitchTit(0)">
					<div :class="Name==0?'color':''">默认</div>
				</span>
				<span v-for="(item,index) in arrTit" v-if="index>0" @click="SwitchTit(index)">
					<div :class="index==Name?'color':''">{{item}}</div>
					<div @click="SwitchSort(index,$event)" v-if="index==Name">
						<img src="@/static/img/normal_price.64de362.png" alt="" v-if="Sort==1" />
						<img src="@/static/img/down_price.e745c7f.png" alt="" v-else-if="Sort==2" />
						<img src="@/static/img/up_price.da045c5.png" alt="" v-else-if="Sort==3" />
					</div>
					<div @click="SwitchSort(index,$event)" v-else>
						<img src="@/static/img/normal_price.64de362.png" alt="" />
					</div>
				</span>
			</div>
		</div>
		<div class="shoplst">
			<div class="shopitem" v-for="(item,index) in ShopTypeList" :key="index" v-if="ShopTypeList.length"
				@click="goDetail(item)">
				<img :src="item.image" alt="" />
				<p>
					<span>￥{{item.price}}</span>
					<span>￥{{item.ot_price}}</span>
				</p>
				<p>{{item.store_name}}</p>
				<p>
					<span>{{item.sales}}人付款</span>
					<span>{{item.star}}分</span>
				</p>
			</div>
			<div class="nothing" v-if="ShopTypeList.length==0">
				<img src="@/static/img/shops.png" alt="" />
				<p>亲，该分类暂无商品呦~</p>
			</div>
		</div>

		<!-- <div class="switchPag">
			<el-pagination background layout="prev, pager, next" :total="pagNum" :page-size="pageSize">
			</el-pagination>
		</div> -->
	</div>
</template>

<script>
	import axios from "axios"
	import store from '../../store.js'
	export default {
		data() {
			return {
				pagNum: 10,
				pageSize: 8,
				arrTit: ['', '价格', '上架时间'],
				Sort: 2,
				Name: 0,
				typeList: [],
				brandList: [],
				ShopTypeList: [],
				titnum: 0,
				typenum: 0,
				brandnum: 0,
				cid: 1,
				sid: 0,
				brand_id: '',
				classnum: 0,
				searchShop: '',
			}
		},
		mounted() {
			this.cid = this.$route.query.cid
			this.sid = this.$route.query.sid
			this.classnum = this.$route.query.classnum
			this.titnum = this.$route.query.titnum
			this.getInfo()
			document.title = '商品分类-广东顺路信息科技有限公司'
		},
		methods: {
			// 获取页面默认信息
			getInfo() {
				const self = this
				axios.get(store.state.url + '/api/category')
					.then(d => {
						self.typeList = d.data.data
					})
					.catch(e => {})
				axios.get(store.state.url + '/api/brand?cid=' + self.cid + '&sid=' + self.sid)
					.then(d => {
						self.brandList = d.data.data
					})
					.catch(e => {})
				axios.get(store.state.url + '/api/pc/get_products', {
						params: {
							page: 1,
							limit: '',
							cid: self.cid,
							sid: self.sid,
							priceOrder: '',
							news: 0,
							brand_id: self.brand_id,
						}
					})
					.then(d => {
						self.ShopTypeList = d.data.data.list
					})
					.catch(e => {})
			},
			// 切换标题
			Switchtype(num, cid) {

				const self = this
				this.titnum = num
				this.cid = cid
				this.sid = 0
				this.classnum = 0
				this.brand_id = ''
				this.getInfo()
				if (this.cid != this.$route.query.cid || this.sid != this.$route.query.sid || this.classnum != this.$route
					.query.classnum || this.titnum != this.$route.query.titnum) {
					this.$router.push('/shoptype?cid=' + self.cid + '&sid=' + self.sid + '&classnum=' + self.classnum +
						'&titnum=' + self.titnum)
				}

			},
			// 切换分类
			Switchclassification(num) {
				const self = this
				this.sid = num
				this.classnum = num
				this.getInfo()
				if (this.cid != this.$route.query.cid || this.sid != this.$route.query.sid || this.classnum != this.$route
					.query.classnum || this.titnum != this.$route.query.titnum) {
					this.$router.push('/shoptype?cid=' + self.cid + '&sid=' + self.sid + '&classnum=' + self.classnum +
						'&titnum=' + self.titnum)
				}
			},
			// 切换品牌
			Switchbrand(num) {
				this.brandnum = num
				this.brand_id = num
				this.getInfo()
			},
			SwitchTit(val) {
				this.Name = val
				if (val == 0) {
					this.getInfo()
				}
			},
			SwitchSort(val, event) {
				const self = this
				console.log(self.ShopTypeList)
				if (this.Name == val) {
					if (this.Sort == 2) {
						this.Sort = 3
						self.ShopTypeList.sort(function(a, b) {
							return a.price > b.price ? 1 : -1
						})
					} else if (this.Sort == 3) {
						this.Sort = 2
						self.ShopTypeList.sort(function(a, b) {
							return a.price < b.price ? 1 : -1
						})
					}
				} else {
					this.Name = val
					this.Sort = 2
				}
				event.stopPropagation()
			},
			// 搜索商品名字
			Searchname() {
				if (this.searchShop != '') {
					this.$router.push('/goodSearch?keyword=' + this.searchShop)
					this.$router.go(0)
				} else {
					this.$message({
						showClose: true,
						message: '请输入要搜索的内容',
						type: 'error'
					});
				}
			},
			// 去详情页
			goDetail(item) {
				console.log(item.id)
				window.open(store.state.url + '/goods_detail/' + item.id, '_blank')
			}
		}
	}
</script>

<style lang="scss">
	.shoptype {
		width: 1380px;
		height: auto;
		margin: 100px auto;
		text-align: left;
		padding-top: 20px;

		.leftlst {
			width: 1380px;
			height: 70px;
			margin: 0 auto;
			margin-top: 30px;
			background-color: #fff;
			display: flex;
			justify-content: space-between;

			.toplst {
				width: auto;
				height: 70px;
				padding: 0px 26px;
				display: flex;

				.alltype {
					min-width: auto;
					height: 70px;
					margin-right: 30px;
					text-align: center;
					line-height: 70px;
					font-size: 14px;

					.type {
						color: red;
						border-bottom: 2px solid red;
					}
				}
			}

			.more {
				width: 110px;
				height: 18px;
				line-height: 18px;
				margin-left: -150px;
				text-align: center;
				margin-top: 25px;
				border-left: 1px solid #c7c7c7;
				z-index: 5000;

				span {
					font-size: 14px;
					color: #282828;
				}

				span:nth-child(1) {
					font-size: 16px;
					color: #8a8a8a;
					margin-right: 4px;
				}

				.moreinfo {
					width: 1380px;
					min-height: 100px;
					padding: 20px 30px;
					background-color: red;
					margin: 25px 0px 0px -1270px;
				}
			}
		}

		.inp {
			width: 650px;
			height: 45px;
			border: 1px solid red;
			position: relative;
			display: flex;
			justify-content: space-between;
			border-radius: 3px;
			margin: 0px auto;
			background-color: #fff;

			i {
				font-size: 19px;
				margin: 13px 15px;
			}

			input {
				width: 550px;
				border: none;
				outline: none;
				font-size: 15px;
			}

			div {
				width: 90px;
				height: 45px;
				font-size: 16px;
				background-color: red;
				color: #fff;
				line-height: 50px;
				text-align: center;
			}
		}

		.rightlst {
			width: 1365px;
			height: auto;
			border-radius: 2px;
			padding: 25px 0px 0px 15px;
			background-color: #fff;
			margin-top: 20px;

			.label {
				width: 1350px;
				height: 45px;
				margin-bottom: 20px;
				font-size: 16px;
				display: flex;

				>div:nth-child(1) {
					width: 50px;
					height: 45px;
					font-size: 14px;
				}

				>div:nth-child(2) {
					width: 1250px;
					height: 45px;
					border-bottom: 1px solid #f0f0f0;
					margin-left: 20px;

					>.scrollx {
						width: 1300px;
						height: 45px;
						display: flex;

						>div {
							min-width: auto;
							height: 45px;
							margin: 0px 35px 0px 0px;
							font-size: 14px;
						}

						.label-color {
							color: red;
						}
					}


				}
			}

			.adjustment {
				width: 935px;
				height: 20px;
				margin-top: 25px;
				font-size: 16px;
				display: flex;

				div:nth-child(2) {
					width: 110px;
					height: 25px;
					border: 1px solid #bcbcbc;
					text-align: center;
					line-height: 27px;
					font-size: 12px;
					color: #767676;
					margin-left: 50px;
					border-radius: 5px;
				}

				div:nth-child(2):hover {
					color: #00aaff;
					border: 1px solid #b8e4ff;
					background-color: #f0f0f0;
				}
			}

			.sort {
				width: 1380px;
				height: 40px;
				margin-top: 25px;
				display: flex;
				position: relative;
				font-size: 14px;

				>div {
					margin-right: 30px;
				}

				span {
					margin-right: 30px;
					display: flex;

					div:nth-child(1) {
						width: auto;
						margin-right: 8px;
					}

					.color {
						color: red;
					}

					div:nth-child(2) {
						width: 13px;
						height: 20px;
						margin: 2px 10px;
						display: flex;
						flex-wrap: wrap;
						position: relative;

						img {
							width: 13px;
							height: 20px;
							position: absolute;
						}
					}
				}

				.tuijian {
					position: absolute;
					right: 0;
					font-size: 12px;
					display: flex;

					div:nth-child(1) {
						width: 12px;
						height: 12px;
						border-radius: 50%;
						margin: 2px 2px 0px 0px;
						background-color: red;
						color: #fff;
					}
				}
			}
		}

		.shoplst {
			width: 1450px;
			height: auto;
			display: flex;
			margin-top: 15px;
			flex-wrap: wrap;

			.shopitem {
				width: 250px;
				height: 370px;
				box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
				margin-bottom: 20px;
				margin-right: 33px;
				background-color: #fff;

				img {
					width: 210px;
					margin: 10px 20px;
				}

				p:nth-of-type(1) {
					width: 210px;
					height: 45px;
					margin: 0px auto;
					font-size: 16px;
					display: -webkit-box;

					span:nth-child(1) {
						font-size: 22px;
						color: #e10000;
						font-weight: bold;
					}

					span:nth-child(2) {
						font-size: 12px;
						color: #afafaf;
						text-decoration: line-through;
					}
				}

				p:nth-of-type(2) {
					width: 200px;
					height: 45px;
					margin: -10px auto;
					font-size: 16px;
					display: -webkit-box;
					/* 使用弹性盒子布局 */
					-webkit-box-orient: vertical;
					/* 垂直方向排列 */
					-webkit-line-clamp: 2;
					/* 设置显示的行数 */
					overflow: hidden;
					/* 超出部分隐藏 */
				}

				p:nth-of-type(3) {
					width: 210px;
					height: 25px;
					margin: 25px auto;
					display: flex;
					justify-content: space-between;
					font-size: 13px;
					color: #aaa;
				}
			}

			.nothing {
				width: 100%;
				height: 600px;
				text-align: center;
				color: #aaa;

				img {
					margin-top: 100px;
				}
			}
		}

		.switchPag {
			width: 100%;
			height: 50px;
			text-align: center;
			padding-top: 20px;
		}
	}

	@media screen and (max-width: 900px) {
		.shoptype {
			width: 27rem;
			height: auto;
			margin: 3rem auto;
			text-align: left;
			padding-top: 2rem;
			overflow-x: hidden;

			.leftlst {
				width: 27rem;
				height: 4rem;
				margin: 0rem auto;
				margin-top: 4rem;
				background-color: #fff;
				display: flex;
				justify-content: space-between;
				overflow-x: auto;

				&::-webkit-scrollbar {
					display: none;
					/* 针对Webkit浏览器 */
				}

				.toplst {
					min-width: 50rem;
					height: 4rem;
					padding: 0px 26px;
					overflow-y: hidden;
					display: flex;

					.alltype {
						width: auto;
						height: 3.8rem;
						margin-right: 2rem;
						text-align: center;
						line-height: 4rem;
						font-size: 0.8rem;
						margin-top: -.1rem;

						.type {
							color: red;
							border-bottom: 2px solid red;
						}
					}
				}

				.more {
					width: 110px;
					height: 18px;
					line-height: 18px;
					margin-left: -150px;
					text-align: center;
					margin-top: 25px;
					border-left: 1px solid #c7c7c7;
					z-index: 5000;

					span {
						font-size: 14px;
						color: #282828;
					}

					span:nth-child(1) {
						font-size: 16px;
						color: #8a8a8a;
						margin-right: 4px;
					}

					.moreinfo {
						width: 1380px;
						min-height: 100px;
						padding: 20px 30px;
						background-color: red;
						margin: 25px 0px 0px -1270px;
					}
				}
			}

			.inp {
				width: 20rem;
				height: 2rem;
				border: 1px solid red;
				position: relative;
				display: flex;
				justify-content: space-between;
				border-radius: 3px;
				margin: -2rem auto;
				background-color: #fff;

				i {
					font-size: 1rem;
					margin: 0.5rem 0.5rem;
				}

				input {
					width: 20rem;
					border: none;
					outline: none;
					font-size: 15px;
					text-align: left;
				}

				div {
					width: 5rem;
					height: 2rem;
					font-size: 0.8rem;
					background-color: red;
					color: #fff;
					line-height: 2rem;
					text-align: center;
				}
			}

			.rightlst {
				width: 27rem;
				height: auto;
				border-radius: 2px;
				padding: 1rem 0rem 0rem 1rem;
				margin-top: 1rem;
				background-color: #fff;

				.label {
					width: 27rem;
					height: 3rem;
					margin-bottom: 1rem;
					font-size: 1rem;
					display: flex;

					>div:nth-child(1) {
						width: 3rem;
						height: 2.5rem;
						font-size: .8rem;
						line-height: 2.15rem;
					}

					>div:nth-child(2) {
						width: 23rem;
						height: 3rem;
						border-bottom: 1px solid #f0f0f0;
						margin-left: 1rem;
						overflow-x: auto;

						&::-webkit-scrollbar {
							display: none;
							/* 针对Webkit浏览器 */
						}

						.scrollx {
							min-width: 30rem;
							height: 3rem;
							display: flex;

							>div {
								width: auto;
								height: 1.2rem;
								margin: 0.3rem 1.8rem 0rem 0rem;
								padding: 0.1rem 0.3rem;
								font-size: 0.8rem;
								line-height: 1.4rem;
								border-radius: 2px;
							}

							.label-color {
								background-color: red;
								color: #fff;
							}
						}


					}
				}

				.sort {
					width: 27rem;
					height: 3rem;
					display: flex;
					position: relative;
					font-size: 0.8rem;

					>div {
						margin: 0rem 1.5rem 0rem 0.35rem;
					}

					span {
						margin-right: 1.8rem;
						display: flex;

						div:nth-child(1) {
							width: auto;
							margin-right: .5rem;
						}

						.color {
							color: red;
						}

						div:nth-child(2) {
							width: .9rem;
							height: 1.25rem;
							margin: 0rem .2rem;
							display: flex;
							flex-wrap: wrap;
							position: relative;

							img {
								width: .9rem;
								height: 1.25rem;
								position: absolute;
							}
						}
					}
				}
			}

			.shoplst {
				width: 27rem;
				height: auto;
				display: flex;
				margin-top: .5rem;
				flex-wrap: wrap;

				.shopitem {
					width: 12rem;
					height: 20rem;
					box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
					margin-bottom: 20px;
					margin: 0.5rem 0rem 0rem 1rem;
					background-color: #fff;

					img {
						width: 10rem;
						height: 10rem;
						margin: .8rem 1rem;
					}

					p:nth-of-type(1) {
						width: 11rem;
						height: 2.8rem;
						margin: 0px auto;
						display: -webkit-box;

						span:nth-child(1) {
							font-size: 1.2rem;
							color: #e10000;
							font-weight: bold;
						}

						span:nth-child(2) {
							font-size: .8rem;
							color: #afafaf;
							text-decoration: line-through;
						}
					}

					p:nth-of-type(2) {
						width: 11rem;
						height: 2.3rem;
						margin: -.9rem auto;
						font-size: .8rem;
						display: -webkit-box;
						/* 使用弹性盒子布局 */
						-webkit-box-orient: vertical;
						/* 垂直方向排列 */
						-webkit-line-clamp: 2;
						/* 设置显示的行数 */
						overflow: hidden;
						/* 超出部分隐藏 */
					}

					p:nth-of-type(3) {
						width: 10.5rem;
						height: 1.5rem;
						margin: 2.2rem auto;
						display: flex;
						justify-content: space-between;
						font-size: .9rem;
						color: #aaa;
					}
				}

				.nothing {
					width: 100%;
					height: 600px;
					text-align: center;
					color: #aaa;

					img {
						margin-top: 100px;
					}
				}
			}

			.switchPag {
				width: 100%;
				height: 50px;
				text-align: center;
				padding-top: 20px;
			}
		}
	}
</style>