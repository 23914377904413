<template>
	<div class="homes">
		<div class="header">
			<div class="container">
				<div class="biao">
					<!-- <img src="@/static/img/biao.png" alt="" /> -->
					<img src="../static/img/logo.png" alt="" />
				</div>
				<div class="gengduo">
					<i class="el-icon-s-fold" v-if='navH=="0rem"' @click="ShowList"></i>
					<i class="el-icon-s-fold i_color" v-else @click="ShowList"></i>
					<el-row class="tac" :style="{height:navH}">
						<el-col :span="12">
							<el-menu default-active=" " class="el-menu-vertical-demo" background-color="#545c64"
								text-color="#fff" active-text-color="#ffd04b">
								<el-menu-item index="1" :style="{ width: '27rem',height:'3.5rem' }">
									<span slot="title" @click="goRouter('/')">首页</span>
								</el-menu-item>
								<el-menu-item index="2" :style="{ width: '27rem',height:'3.5rem' }">
									<span slot="title" @click="goRouter('/shop')">API市场</span>
								</el-menu-item>
								<el-submenu index="3" :style="{ width: '27rem'}">
									<template slot="title">
										<span slot="title">文档中心</span>
									</template>
									<el-menu-item index="3-1" :style="{ width: '27rem',height:'3.5rem' }"
										@click="goRouter('/developer')">开发者文档</el-menu-item>
									<el-menu-item index="3-2" :style="{ width: '27rem',height:'3.5rem' }"
										@click="goRouter('/apis/100')">API文档</el-menu-item>
								</el-submenu>
								<el-menu-item index="4" :style="{ width: '27rem',height:'3.5rem' }">
									<span slot="title" @click="goRouter('/announce')">平台公告</span>
								</el-menu-item>
								<el-menu-item index="5" :style="{ width: '27rem',height:'3.5rem' }">
									<span slot="title"
										@click="goRouter('/shoptype',{'cid':'1','sid':'0','classnum':'0','titnum':'0' })">商品分类</span>
								</el-menu-item>
								<el-menu-item index="6" :style="{ width: ' 27rem',height:'3.5rem' }">
									<span slot="title" @click="goSupply">供应链</span>
								</el-menu-item>
								<el-menu-item index="7" :style="{ width: '27rem',height:'3.5rem' }">
									<span slot="title" @click="goRouter('/dynamic')">公司动态</span>
								</el-menu-item>
							</el-menu>
						</el-col>
					</el-row>
				</div>
			</div>
		</div>
		<div class="content">
			<router-view @settlein='settlein'></router-view>
		</div>
		<div class="combottom">
			<div class="box">
				<div>
					<div class="tit">产品</div>
					<ul>
						<li>微信小程序</li>
						<li>抖音小程序接入</li>
						<li>跨界营销功能</li>
						<li>视频号</li>
						<li>小红书等全域运营</li>
					</ul>
				</div>
				<div>
					<div class="tit">新手入门</div>
					<ul>
						<li>平台公告</li>
						<li>平台规范</li>
						<li>在线文档</li>
					</ul>
				</div>
				<div>
					<div class="tit">联系方式</div>
					<ul>
						<li>客服电话:400-6757-688</li>
						<li>客服微信:K4006757688</li>
						<li>邮箱:skebao@139.com</li>
						<li>公司地址:广东省惠州市惠城区小金口金兴街19号之2一层</li>
					</ul>
				</div>
				<div class="code">
					<div class="tit">扫码关注</div>
					<!-- <img src="../static/img/qrcode_for_gh_f2873ae73085_258.jpg" alt="" /> -->
					<img src="../static/img/wx-qrcode.png" alt="" />
				</div>
			</div>
			<div class="num">
				<span style="color: #fff; font-size: 12px;">
					<a href="https://beian.miit.gov.cn/" target="_blank">
						版权所有 © 2009-2024&nbsp; 广东顺路信息科技有限公司
						&nbsp; &nbsp; &nbsp;
						<!-- <span>粤ICP备2024173212号-4</span> -->
						<span>粤ICP备2023068720号-1</span>
					</a>
				</span>
			</div>
		</div>
		<div class="helps">
			<div class="helplist">
				<div @click="goCustomerService">
					<i class="el-icon-headset"></i>
					<p>客服</p>
				</div>
				<div @click="goTop">
					<i class="el-icon-top"></i>
					<p>返回顶部</p>
				</div>
			</div>
			<div class="showout" @click="Showout">
				<i class="el-icon-d-arrow-left" v-if="seeout"></i>
				<i class="el-icon-d-arrow-right" v-else></i>
			</div>
		</div>
		<Login v-if="loading" @close="closeModel" @bianshow="goRegister" />
		<Register v-if="judgeReg" @close="closeRegister" />
	</div>
</template>
<script>
	import Login from './login.vue'
	import Register from './register.vue'
	import store from '../store.js'
	export default {
		components: {
			Login,
			Register,
		},
		data() {
			return {
				list: ['', '/home', '/shop', ['/developer', '/apis'], '/announce', '/shoptype', '', '/dynamic',
					'/goodSearch', '/goodDetail'
				],
				loading: false,
				judgeReg: false,
				activeIndex: '1',
				seeout: false,
				navH: '0rem',
			}
		},
		mounted() {
			const self = this
			this.getData()
		},
		methods: {
			// 页面刷新时保留当前页面
			getData() {
				var self = this
				this.list.map((v, k) => {
					if (this.$route.path == v) {
						self.activeIndex = k
						return
					} else if (typeof(v) == 'object') {
						self.forArr(v, k)
					}
				})
			},
			Showout() {
				this.seeout = !this.seeout
				const show = document.querySelector('.showout')
				const help = document.querySelector('.helplist')
				if (this.seeout) {
					help.style.marginRight = '3rem'
				} else {
					help.style.marginRight = '-5rem'
				}
			},
			forArr(arr, key) {
				var self = this
				arr.map((d, k) => {
					if (this.$route.path == d) {
						self.activeIndex = key + '-' + (k + 1)
					}
					if (typeof(d) == 'object') {
						self.forArr(d, k)
					}
				})
			},
			goTop() {
				document.documentElement.scrollTop = 0
			},
			// 去登录
			goLogin() {
				this.loading = true
			},
			// 去注册
			goRegister() {
				this.loading = false
				this.judgeReg = true
			},
			// 关闭登录
			closeModel() {
				this.loading = false
			},
			// 关闭注册
			closeRegister() {
				this.judgeReg = false
			},
			settlein() {
				this.loading = true
			},
			// 多级菜单
			handleSelect(index, keypath) {
				console.log(index, keypath)
			},
			goUpdate() {
				this.$router.go(0)
			},
			// 去供应链商城
			goSupply() {
				window.open(store.state.url, '_blank')
			},
			// 显示或隐藏pc端导航栏
			ShowList() {
				const self = this
				if (self.navH == '0rem') {
					self.navH = '35rem'
				} else {
					self.navH = '0rem'
				}
			},
			// pc端路由跳转
			goRouter(url, data) {
				if (this.$route.path == url) {
					this.navH = '0rem'
					return false
				}
				if (data) {
					this.$router.push({
						path: url,
						query: data,
					})
				} else {
					this.$router.push(url)
				}
				this.navH = '0rem'
				document.documentElement.scrollTop = 0
			},
			goCustomerService() {
				window.location.href = 'https://work.weixin.qq.com/kfid/kfcd7b30ebea2561bf4'
			},
		}
	}
</script>
<style lang="scss">
	* {
		margin: 0;
		padding: 0;
		text-decoration: none;
		list-style: none;
		text-align: center;
	}

	.router-link-exact-active,
	.router-link-active {
		text-decoration: none;
		color: inherit;
	}

	.homes {
		width: 100%;
		min-height: 1000px;
		background-color: #f9f9f9;
		position: absolute;
		top: 0;

		>.header {
			width: 100%;
			height: 80px;
			background-color: #fff;
			z-index: 1000;
			position: fixed;
			top: 0px;
			background-color: rgb(0, 0, 61);

			.container {
				width: 1400px;
				height: 80px;
				margin: 0px auto;
				display: flex;
				justify-content: space-between;
				position: relative;

				.biao {
					width: 125px;
					height: 80px;
					overflow: hidden;

					>img {
						width: 100%;
						height: 100%;
						// margin-top: 10px;
					}
				}

				.title {
					width: auto;
					height: 70px;
					overflow: hidden;

					>ul {
						display: flex;
						line-height: 70px;
						font-size: 14px;
						height: 70px;
						font-weight: bold;

						li {
							color: #000;
							line-height: 70px;
							height: 70px;
							margin: 0px 25px;

							.el-submenu__title {
								padding-top: 5px;
							}
						}

						.tit_se {
							height: 58px;
							color: #0b4ffc;
							box-sizing: content-box;
							border-bottom: 2px solid #0b4ffc;
						}

					}
				}

				.login {
					width: 260px;
					height: 70px;
					line-height: 60px;

					span {
						margin-left: 100px;
						font-size: 12px;
						color: #0b4ffc;
					}

					button {
						width: 56px;
						height: 32px;
						font-size: 12px;
						margin-left: 20px;
						background-color: #0b4ffc;
						border: none;
						color: #fff;
						border-radius: 3px;
					}
				}

				.gengduo {
					position: absolute;
					font-size: 1.5rem;
					display: none;
				}
			}
		}

		.content {
			width: 100%;
			height: auto;
			margin-bottom: 450px;
			margin-top: 90px;
		}

		.combottom {
			width: 100%;
			height: 400px;
			background-color: #1e1e28;
			margin: 0px -77px 0px 0px;
			position: absolute;
			bottom: 0;

			.num {
				width: 500px;
				margin: 0 auto;
				line-height: 60px;

				a {
					text-decoration: none;
					color: #fff;
				}
			}

			.box {
				width: 1130px;
				height: 240px;
				margin: 0px auto;
				margin-top: 90px;
				padding-left: 150px;
				display: flex;
				border-bottom: 1px solid #fff;

				>div {
					min-width: auto;
					min-height: 100px;
					margin-right: 50px;

					.tit {
						width: 100%;
						height: 30px;
						padding-bottom: 15px;
						text-align: left;
						font-size: 20px;
						color: #fff;
					}

					ul>li {
						min-width: 180px;
						height: 35px;
						font-size: 15px;
						color: #ffffff99;
						line-height: 30px;
						text-align: left;
					}

					ul>li:hover {
						color: #fff;
					}
				}

				.code {
					width: 300px;
					height: 200px;
					margin-top: -10px;

					img {
						width: 150px;
						height: 150px;
						margin: -10px 0px 0px -100px;
					}
				}
			}
		}

		.helps {
			width: 100%;
			height: 0px;
			z-index: 5000;
			position: fixed;
			bottom: 100px;
			background-color: red;

			.helplist {
				width: 50px;
				height: auto;
				margin-bottom: 85px;
				float: right;
				margin-right: -120px;
				margin-top: -450px;
				background-color: #f3f3f3;
				border-radius: 5px;
				transition: margin-right 0.3s ease;

				div {
					width: 50px;
					height: auto;
					padding: 10px 0px;
					color: #666;
					font-size: 14px;

					p {
						width: 30px;
						height: auto;
						margin-left: 10px;
					}
				}
			}

			.showout {
				width: 50px;
				height: 50px;
				border-radius: 50%;
				background-color: rgba(0, 0, 0, 0.2);
				line-height: 50px;
				position: absolute;
				right: 20px;
				bottom: 0px;
				font-size: 25px;
				color: #fff;
			}
		}
	}

	@media screen and (max-width: 900px) {
		.homes {
			width: 27rem;
			min-height: 1000px;
			background-color: #f9f9f9;
			margin: 0 auto;
			overflow-x: hidden;
			position: relative;

			>.header {
				width: 27rem;
				height: 4.2rem;
				background-color: #fff;
				z-index: 1000;
				position: fixed;
				top: 0rem;
				background-color: rgb(0, 0, 61);

				.container {
					width: 27rem;
					height: 3.75rem;
					margin: 0px auto;
					display: flex;
					justify-content: space-between;
					position: relative;

					.biao {
						width: 7rem;
						height: 4.2rem;
						overflow: hidden;
						margin-left: 1rem;
						background-image: url(../static/img/logo.png);
						// margin-top: .65rem;
						background-size: 100% 100%;

						img {
							display: none;
							width: 12.4rem;
							margin-top: .5rem;
						}
					}

					.title {
						display: none;
					}

					.gengduo {
						display: block;
						right: 0rem;
						top: 1rem;
						position: absolute;

						i {
							font-size: 1.8rem;
							margin-top: .2rem;
							color: #cfcfcf;
						}

						.i_color {
							color: #0b4ffc;
						}

						.el-row {
							// height: 0rem;
							overflow: hidden;
							transition: height 0.5s;
							margin-left: -23rem;
							margin-top: 1.1rem;

							i {
								font-size: 1rem;
								margin-top: -.5rem;
							}
						}
					}

					.login {
						display: none;
					}
				}
			}

			.content {
				width: 27rem;
				height: auto;
				margin-bottom: 40rem;
			}

			.combottom {
				width: 27rem;
				height: 40rem;
				background-color: #1e1e28;
				margin: 1rem -1rem 0px 0px;
				position: absolute;
				bottom: 0;

				.box {
					width: 20rem;
					height: 32rem;
					margin: 0rem auto;
					margin-top: 2rem;
					padding-left: 1rem;
					display: flex;
					border-bottom: 1px solid #fff;
					flex-wrap: wrap;
					// background-color: red;

					>div {
						min-width: 24rem;
						height: 3rem;
						margin-right: 1rem;

						>.tit {
							width: 24rem;
							height: 2rem;
							padding-bottom: 0rem;
							text-align: left;
							font-size: 1rem;
							color: #fff;
						}

						>ul {
							width: 24rem;
							height: auto;
							display: flex;
							flex-wrap: wrap;
							margin-top: -0.5rem;

							>li {
								width: 1rem;
								height: 0.8rem;
								font-size: 0.8rem;
								color: #ffffff99;
								line-height: 0.9rem;
								// background-color: aqua;
								margin-top: 0.6rem;
							}
						}

						>ul>li:hover {
							color: #fff;
						}
					}

					.code {
						img {
							width: 10rem;
							height: 10rem;
							margin: -1rem 0rem 0rem -1rem;
						}
					}
				}

				>.num {
					padding-top: 1rem;
					width: 19rem;
					height: 3rem;
					line-height: 1.5rem;
				}
			}

			.helps {
				width: 27rem;
				height: 0px;
				z-index: 5000;
				position: fixed;
				bottom: 6.5rem;
				background-color: red;

				.helplist {
					width: 3rem;
					height: auto;
					margin-bottom: 5.1rem;
					float: right;
					margin-right: -7rem;
					margin-top: -28rem;
					background-color: #f3f3f3;
					border-radius: 5px;
					transition: margin-right 0.3s ease;

					div {
						width: 3rem;
						height: auto;
						padding: 10px 0px;
						color: #666;
						font-size: 14px;

						p {
							width: 30px;
							height: auto;
							margin-left: 10px;
						}
					}
				}

				.showout {
					width: 2rem;
					height: 2rem;
					border-radius: 50%;
					background-color: rgba(0, 0, 0, 0.2);
					line-height: 2rem;
					position: absolute;
					right: 1.5rem;
					bottom: 2rem;
					font-size: 1rem;
					color: #fff;
				}
			}
		}
	}
</style>