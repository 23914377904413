import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		loading: false,
		judgeReg: false,
		url: 'https://multi-store.crmeb.net',
	},
	mutations: {
		showlogin(state, newMessage) {
			state.loading = newMessage
		},
		showRegister(state, newMessage) {
			state.judgeReg = newMessage
		},
	},
	actions: {
		incrementAsync({
			commit
		}) {
			setTimeout(() => {
				commit('increment')
			}, 1000)
		}
	},
	getters: {
		doubleCount(state) {
			return state.count * 2
		}
	}
})