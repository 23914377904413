<template>
	<div class="gologin" @click="closeModal">
		<div class="info">
			<div class="mode">
				<div class="switchLogin type_line">手机号登录</div>
				<div class="switchLogin">密码登录</div>
			</div>
			<div class="shouji" v-if="type=='phone'">
				<div :class="userprompt?'promptinfo show':'promptinfo'">请输入正确的手机号</div>
				<div class="phone">
					<i class="el-icon-mobile-phone"></i>
					<input type="text" :class="userprompt?'errorBor':''" v-model="phoneinfo.phonenum"
						@input="isDone(phoneinfo.phonenum)" placeholder="请输入手机号" />
				</div>
				<div class="Verification">
					<div>
						<i class="el-icon-message"></i>
						<input type="text" v-model="phoneinfo.Verification" @input="isDoneVer" placeholder="手机验证码" />
					</div>
					<div>
						获取验证码
					</div>
				</div>
			</div>
			<div class="password" v-if="type=='password'">
				<div :class="userprompt?'promptinfo show':'promptinfo'">请输入正确的手机号</div>
				<div class="phone">
					<i class="el-icon-mobile-phone"></i>
					<input type="text" :class="userprompt?'errorBor':''" v-model="passinfo.phonenum"
						@input="isDone(passinfo.phonenum)" placeholder="请输入手机号" />
				</div>
				<div class="phone_pass">
					<i class="el-icon-lock"></i>
					<input type="password" v-model="passinfo.passnum" @input="isDonePass" placeholder="请输入您的密码" />
				</div>
			</div>
			<div class="newUser">新用户注册</div>
			<el-row v-if="is_login">
				<el-button class="start_btn" type="primary" :disabled="is_login">立即登录</el-button>
			</el-row>
			<el-row v-else>
				<el-button class="end_btn" type="primary" :disabled="is_login" @click="Sublogin">立即登录</el-button>
			</el-row>
			<!-- <div class="end_btn" v-else @click="Sublogin" disabled>立即登录</div> -->
			<div class="protocol">
				<span>登录即视为同意</span>
				<span>《用户协议》</span>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		isValidPhoneNumber
	} from '@/api/app.js'
	export default {
		data() {
			return {
				type: 'phone',
				// 账号密码登录
				passinfo: {
					phonenum: '',
					passnum: '',
				},
				// 手机号登录
				phoneinfo: {
					phonenum: '',
					Verification: '',
				},
				is_login: true,
				userprompt: false,
			}
		},
		props: {
			message: String
		},
		mounted() {
			this.loginType()
			document.title = '登录-众享数字有限公司';
		},
		methods: {
			closeModal(e) {
				if (e.target.getAttribute('class') == 'gologin') {
					this.$emit('close')
				}
				if (e.target.getAttribute('class') == 'newUser') {
					this.$emit('bianshow')
				}
			},
			// 提交账号信息
			Sublogin() {
				if (this.type == 'phone') {}
				if (this.type == 'password') {
					if (this.passinfo.phonenum == '') {
						this.$message({
							message: '请输入您的手机号',
							type: 'error',
							customClass: 'prompts'
						});
					} else if (this.passinfo.passnum == '') {
						this.$message({
							message: '请输入密码后再登陆',
							type: 'error',
							customClass: 'prompts'
						});
					} else {
						this.$message({
							message: '账号或密码出现错误，请重新再试',
							type: 'error',
							customClass: 'prompts'
						});
					}
				}
			},
			// 输入时判断输入的手机号是否正确,并判断信息是否填完整
			isDone(number) {
				if (isValidPhoneNumber(number)) {
					this.userprompt = false
					// 如果信息完整就可以登录
					if (this.phoneinfo.Verification != '' || this.passinfo.passnum != '') {
						this.is_login = false
					}
				} else {
					this.userprompt = true
					this.is_login = true
				}
			},
			// 输入验证码后，判断手机号登录信息是否完整
			isDoneVer() {
				if (isValidPhoneNumber(this.phoneinfo.phonenum) && this.phoneinfo.Verification != '') {
					this.is_login = false
				} else {
					this.is_login = true
				}
			},
			// 输入密码后，判断手机号登录信息是否完整
			isDonePass() {
				if (isValidPhoneNumber(this.passinfo.phonenum) && this.passinfo.passnum != '') {
					this.is_login = false
				} else {
					this.is_login = true
				}
			},
			// 切换登录
			loginType() {
				const self = this
				var types = document.querySelectorAll('.switchLogin')
				types.forEach((v, k) => {
					v.onclick = function() {
						for (var i = 0; i < types.length; i++) {
							types[i].setAttribute('class', 'switchLogin')
						}
						this.setAttribute('class', 'switchLogin type_line')
						if (this.innerHTML == '手机号登录') {
							self.type = 'phone'
						}
						if (this.innerHTML == '密码登录') {
							self.type = 'password'
						}
						//清除上一个登录方式的信息
						self.userprompt = false
						self.is_login = true
						self.phoneinfo.phonenum = ''
						self.phoneinfo.Verification = ''
						self.passinfo.phonenum = ''
						self.passinfo.passnum = ''
					}
				})
			},
		}
	}
</script>

<style lang="scss">
	.prompts {
		z-index: 10000 !important;
	}

	input {
		text-align: left;
	}

	.gologin {
		width: 100%;
		height: 900px;
		background-color: rgba(0, 0, 0, 0.5);
		position: fixed;
		top: 0px;
		z-index: 5000;
		text-align: left;

		.info {
			width: 560px;
			height: 550px;
			position: absolute;
			top: 50%;
			left: 50%;
			background-color: #fff;
			transform: translate(-50%, -50%);
			border-radius: 2px;

			.mode {
				width: 390px;
				height: 45px;
				display: flex;
				justify-content: space-around;
				margin: 50px auto;
				line-height: 45px;

				div {
					height: 45px;
				}

				.type_line {
					border-bottom: 3px solid #000;
					font-weight: bold;
				}
			}

			.password {
				width: 100%;
				margin: 0 auto;

				.promptinfo {
					width: 390px;
					margin: -15px auto;
					text-align: left;
					font-size: 12px;
					color: red;
					opacity: 0;
				}

				.show {
					opacity: 1;
				}

				.phone {
					width: 360px;
					height: 45px;
					margin: 15px auto;
					position: relative;

					input {
						width: 100%;
						height: 45px;
						padding-left: 30px;
						outline: none;
						border: 1px solid #c6c6c6;
						border-radius: 5px;
						margin-left: -15px;
						text-align: left;
					}

					.errorBor {
						border: 1px solid red;
					}

					i {
						position: absolute;
						top: 15px;
						left: -5px;
						color: #c6c6c6;
					}
				}

				.phone_pass {
					width: 360px;
					height: 45px;
					margin: 45px auto;
					position: relative;

					input {
						width: 100%;
						height: 45px;
						padding-left: 30px;
						outline: none;
						border: 1px solid #c6c6c6;
						border-radius: 5px;
						margin-left: -15px;

					}

					i {
						position: absolute;
						top: 15px;
						left: -5px;
						color: #c6c6c6;
					}
				}
			}

			.shouji {
				width: 100%;
				margin: 0 auto;

				.promptinfo {
					width: 390px;
					margin: -15px auto;
					text-align: left;
					font-size: 12px;
					color: red;
					opacity: 0;
				}

				.show {
					opacity: 1;
				}

				.phone {
					width: 360px;
					height: 45px;
					margin: 15px auto;
					position: relative;

					input {
						width: 100%;
						height: 45px;
						padding-left: 30px;
						outline: none;
						border: 1px solid #c6c6c6;
						border-radius: 5px;
						margin-left: -15px;
					}

					.errorBor {
						border: 1px solid red;
					}

					i {
						position: absolute;
						top: 15px;
						left: -5px;
						color: #c6c6c6;
					}
				}

				// .scroll {
				// 	width: 400px;
				// 	height: 45px;
				// 	margin: -8px auto;
				// 	background-color: aqua;
				// }

				.Verification {
					width: 394px;
					height: 45px;
					margin: 45px auto;
					display: flex;
					justify-content: space-between;

					div:nth-child(1) {
						width: 230px;
						height: 45px;
						position: relative;

						input {
							width: 100%;
							height: 45px;
							padding-left: 30px;
							outline: none;
							border: 1px solid #c6c6c6;
							border-radius: 5px;
						}

						i {
							position: absolute;
							top: 15px;
							left: 9px;
							color: #c6c6c6;
						}
					}

					div:nth-child(2) {
						width: 110px;
						height: 45px;
						line-height: 45px;
						text-align: center;
						font-size: 14px;
						color: #c0c4cc;
						border: 1px solid #c6c6c6;
						border-radius: 5px;
					}
				}
			}



			.newUser {
				width: 390px;
				height: 25px;
				margin: 20px auto;
				text-align: left;
				color: #0b4ffc;
				font-size: 14px;
			}

			.end_btn {
				width: 390px;
				height: 45px;
				margin: -25px auto;
				color: #fff;
				font-size: 16px;
				border-radius: 5px;
				background-color: #0b4ffc;
			}

			.start_btn {
				width: 390px;
				height: 45px;
				margin: -25px auto;
				color: #fff;
				font-size: 16px;
				border-radius: 5px;
			}

			.protocol {
				width: 390px;
				height: 60px;
				margin: 45px auto;
				line-height: 85px;

				span {
					font-size: 15px;
					color: #606266;
				}

				span:nth-child(2) {
					color: red;
				}
			}
		}
	}
</style>