<template>
	<div class="announce">
		<div class="top">
			<div class="title">
				<div>开放平台公告中心</div>
				<div></div>
			</div>
			<div class="search">
				<div></div>
				<div class="inp">
					<div>
						<input type="text" placeholder="输入搜索关键词" />
						<div class="btn">搜索</div>
					</div>
					<div>
						<p>热门搜索:</p>
						<p>点餐</p>
						<p>外卖</p>
						<p>电影票</p>
						<p>肯德基</p>
						<p>花费</p>
					</div>
				</div>
			</div>
		</div>
		<div class="text">
			<div class="type">
				<div class="tit">平台公告目录</div>
				<div class="scroll_item">
					<div v-for="(i,k) in 3" :class="k==ankey?'item back':'item'" :key="k" @click="SwitchAn(k)">
						公告{{k}}
					</div>
				</div>
			</div>
			<div class="content">
				<div class="box">
					<div class="item" v-for="(i,k) in 3" :key="k">
						<span>美团霸王餐API接口正式对外开放</span>
						<span>2024-04-11 13:14:52</span>
					</div>
					<div class="page">
						<el-pagination background layout="prev, pager, next" :page-size="pagnum" :total="length">
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				length: 10,
				pagnum: 5,
				ankey: 0,
			}
		},
		mounted() {
			document.title = '平台公告-广东顺路信息科技有限公司';
		},
		methods: {
			SwitchAn(key) {
				this.ankey = key
			}
		}
	}
</script>

<style lang="scss">
	.announce {
		width: 100%;
		height: auto;
		margin-top: 80px;

		.top {
			width: 100%;
			height: 250px;

			.title {
				width: 1280px;
				height: 76px;
				margin: 100px auto;

				div:nth-child(1) {
					width: 424px;
					height: 86px;
					line-height: 110px;
					font-size: 44px;
					color: #1e1e28;
					text-align: center;
					margin: 30px auto;
				}
			}

			.search {
				width: 1280px;
				height: 75px;
				margin: 0 auto;
				margin-top: -15px;

				.inp {
					width: 750px;
					height: 75px;
					margin: 55px auto;

					div:nth-child(1) {
						width: 750px;
						height: 48px;
						overflow: hidden;
						position: relative;
						border: 1px solid #ddd;
						border-radius: 5px;
						overflow: hidden;

						input {
							width: 100%;
							height: 48px;
							padding-left: 15px;
							outline: none;
							border: none;
							text-align: left;
						}

						.btn {
							width: 74px;
							height: 48px;
							position: absolute;
							background-color: #0b4ffc;
							font-size: 22px;
							top: 0;
							right: 0;
							color: #fff;
							line-height: 48px;
							padding-left: 30px;
						}
					}

					div:nth-child(2) {
						width: 750px;
						height: 17px;
						display: flex;

						p {
							width: auto;
							height: 17px;
							margin-top: 10px;
							margin: 5px;
							font-size: 12px;
							color: #000000A6;
						}
					}
				}
			}
		}

		.text {
			width: 1380px;
			height: 800px;
			margin: 65px auto;
			display: flex;
			justify-content: space-between;
			padding-top: 20px;

			.type {
				width: 250px;
				height: 800px;
				background-color: #fff;
				text-align: left;

				.tit {
					width: 220px;
					height: 55px;
					font-size: 16px;
					font-weight: bold;
					line-height: 60px;
					padding-left: 30px;
					text-align: left;
				}

				.item {
					width: 220px;
					height: 50px;
					font-size: 14px;
					line-height: 50px;
					padding-left: 30px;
					text-align: left;
				}

				.back {
					background-color: #0b4ffc;
					color: #fff;
				}
			}

			.content {
				width: 1120px;
				height: 800px;
				background-color: #fff;

				.box {
					width: 1070px;
					height: 700px;
					margin: 30px;
					text-align: left;
					position: relative;

					.item {
						width: 1070px;
						height: 65px;
						line-height: 70px;
						border-bottom: 1px solid #e1e1e1a6;
						display: flex;
						justify-content: space-between;
						color: #000;

						span:nth-child(1) {
							font-size: 18px;
							font-weight: bold;
						}

						span:nth-child(2) {
							font-size: 15px;
							color: #6d6d6d;
						}
					}

					.page {
						width: 1070px;
						height: 50px;
						margin-top: 25px;
						display: flex;
						justify-content: flex-end;
						position: absolute;
						bottom: 20px;
					}
				}
			}
		}
	}

	@media screen and (max-width: 900px) {
		.announce {
			width: 100%;
			height: auto;
			margin-top: -3rem;
			overflow-x: hidden;

			.top {
				width: 100%;
				height: 8rem;

				.title {
					width: 27rem;
					height: 2rem;
					margin: 5rem auto;
					margin-bottom: 4rem;

					div:nth-child(1) {
						width: 27rem;
						height: 2rem;
						line-height: 5rem;
						font-size: 1.5rem;
						color: #1e1e28;
						text-align: center;
						margin: 1rem auto;
					}
				}

				.search {
					width: 27rem;
					height: 4rem;
					margin: 0 auto;
					margin-top: -1rem;

					.inp {
						width: 20rem;
						height: 2rem;
						margin: 0rem auto;

						div:nth-child(1) {
							width: 20rem;
							height: 2rem;
							overflow: hidden;
							position: relative;
							border: 1px solid #ddd;
							border-radius: 5px;
							overflow: hidden;
							background-color: red;

							input {
								width: 100%;
								height: 2rem;
								padding-left: 1rem;
								outline: none;
								border: none;
								text-align: left;
								font-size: 0.7rem;
							}

							.btn {
								width: 2rem;
								height: 2rem;
								position: absolute;
								background-color: #0b4ffc;
								font-size: 1rem;
								top: 0;
								right: 0;
								color: #fff;
								line-height: 2rem;
								padding-right: 1.5rem;
							}
						}

						div:nth-child(2) {
							width: 20rem;
							height: 1rem;
							display: flex;

							p {
								width: auto;
								height: 1rem;
								margin-top: 0.6rem;
								margin: 0.3rem;
								font-size: 0.7rem;
								color: #000000A6;
							}
						}
					}
				}
			}

			.text {
				width: 27rem;
				height: 50rem;
				margin: -1rem auto;
				padding-top: 4rem;
				display: flex;
				flex-wrap: wrap;

				.type {
					width: 27rem;
					height: 3rem;
					background-color: #fff;
					overflow-x: auto;

					&::-webkit-scrollbar {
						display: none;
						/* 针对Webkit浏览器 */
					}

					.tit {
						display: none;
					}

					.scroll_item {
						width: 50rem;
						height: 3rem;

						.item {
							width: 5rem;
							height: 3rem;
							font-size: .8rem;
							line-height: 3rem;
							float: left;
							padding: 0rem .5rem;
							text-align: center;
						}

						.back {
							background-color: #0b4ffc;
							color: #fff;
						}
					}
				}

				.content {
					width: 27rem;
					min-height: 60rem;
					background-color: #fff;
					margin-top: 1rem;

					.box {
						width: 27rem;
						min-height: 60rem;
						text-align: left;
						overflow-x: hidden;
						overflow-y: hidden;
						margin-left: 0.1rem;
						margin-top: 1rem;

						.item {
							width: 27rem;
							height: 5rem;
							line-height: 4rem;
							border-bottom: 1px solid #e1e1e1a6;
							display: flex;
							flex-wrap: wrap;
							color: #000;

							span:nth-child(1) {
								width: 27rem;
								font-size: .9rem;
								font-weight: bold;
								margin: -.5rem 1rem 0rem -5.5rem;
							}

							span:nth-child(2) {
								width: 27rem;
								font-size: .8rem;
								color: #6d6d6d;
								margin: -1.5rem 0rem 0rem -8.5rem;
							}
						}

						.page {
							width: 1070px;
							height: 50px;
							margin-top: 25px;
							display: flex;
							justify-content: flex-end;
						}
					}
				}
			}
		}
	}
</style>