<template>
	<div class="home">
		<div class="swiper">
			<el-carousel height="550px" :interval='2000'>
				<el-carousel-item v-for="(item,index) in swiperLst" :key="index">
					<h3 :style="{backgroundImage: 'url(' + item.img + ')' }" @click="swiperDetail"></h3>
				</el-carousel-item>
			</el-carousel>
		</div>
		<div class="API">
			<div class="center">
				<div class="list" v-for="(item,index) in gklst" :key="index" @click="goAnnouncement">
					<div class="name">{{item.name}}</div>
					<div class="time">{{item.time}}</div>
				</div>
				<div class="list">
					<div class="qita">
						<router-link to="/announce">
							<span>更多公告</span>
							<span><i class="el-icon-caret-right"></i></span>
						</router-link>
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="typeshop">
			<div class="shopbox" v-for="(val,key) in shopList" :key="key" v-if=" val.productList.length">
				<div class="tit">
					<div>{{val.cate_name}}</div>
					<div @click="goTypeshop(val,key)">更多<i class="el-icon-arrow-right"></i></div>
				</div>
				<div class="listbox">
					<div class="list" v-for="(item,index) in val.productList" v-if="index<5" :key="index">
						<img :src="item.image" alt="" />
						<div class="money">
							<span>￥{{item.price}}</span>
							<span>￥{{item.ot_price}}</span>
						</div>
						<div class="text">{{item.store_name}}</div>
						<div class="history">
							<span>{{item.sales}}人付款</span>
							<span>{{item.star}}分</span>
						</div>
					</div>
				</div>
			</div>
		</div> -->
		<div class="programme">
			<div class="title">
				精准高效的解决方案
			</div>
			<div class="introduce">
				整合商品、渠道资源 行业领先的技术资源 驱动企业实现业绩增长
			</div>
			<div class="list">
				<div class="item" v-for="(item,index) in Solutionlst" :key="index">
					<div class="box">
						<div class="img">
							<img :src="item.img" alt="" />
						</div>
						<div class="name">
							{{item.title}}
						</div>
						<div class="describe">
							{{item.name}}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="service">
			<div class="name">
				产品与服务
			</div>
			<div class="introduce">
				丰富的数字资源接口完善的技术支持方案
			</div>
			<div class="list">
				<div class="item" v-for="(i,k) in serviceLst" :key="k">
					<div class="box">
						<div class="img">
							<img :src="i.img" alt="" />
						</div>
						<div class="content">
							<p>{{i.title}}</p>
							<p>{{i.name}}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="advantage">
			<div class="advantagename">
				合作优势
			</div>
			<div class="advantageIntroduce">
				致力做你最信任的合作伙伴
			</div>
			<div class="advantageList">
				<div class="item" v-for="(i,k) in advantageLst" :key="k">
					<div class="img">
						<img :src="i.img" alt="" />
					</div>
					<div class="itemname">
						{{i.title}}
					</div>
					<div class="itemintroduce">
						{{i.name}}
					</div>
				</div>
			</div>
		</div>
		<div class="Settle_in">
			<div class="inName">
				一步入驻&nbsp;即享专业服务
			</div>
			<div class="intype">
				<div class="item" v-for="(i,k) in SettleLst" :key="k">
					<div class="img">
						{{i.num}}
					</div>
					<div class="name">{{i.title}}</div>
					<div class="miaoshu">{{i.name}}</div>
				</div>
			</div>
			<div class="inbtn" @click="SettleIn">
				立即入驻
			</div>
		</div>
	</div>
</template>

<script>
	// import Login from '@/components/login.vue'
	// import Register from '@/components/register.vue'
	// import store from '../../store.js'
	// import axios from 'axios'
	export default {
		data() {
			return {
				shopList: [],
				swiperLst: [{
						'img': require('@/static/img/zx1.jpg'),
						'url': '',
					},
					{
						'img': require('@/static/img/zx2.jpg'),
						'url': '',
					},
					{
						'img': require('@/static/img/zx3.jpg'),
						'url': '',
					},
					{
						'img': require('@/static/img/zx4.jpg'),
						'url': '',
					},
				],
				gklst: [{
						name: '店铺外卖API接口正式开放',
						time: '2024-5-13',
					},
					{
						name: '排队免单活动正式上线',
						time: '2024-5-13',
					},
					{
						name: '商家入驻须知',
						time: '2024-5-13',
					},
					{
						name: '商家入驻须知',
						time: '2024-5-13',
					},
					{
						name: '商家入驻须知',
						time: '2024-5-13',
					},
				],
				Solutionlst: [{
						name: '覆盖淘宝、京东、拼多多、唯品会等导购优惠券，高效转化,商品搜索等众多API。',
						title: '导购电商场景',
						img: require('@/static/img/8.png')
					},
					{
						name: '覆盖影院、外卖、出行、团购等本地生活CPS返利，吃喝玩乐一站式消费，省钱又赚钱。',
						title: '吃喝玩乐场景',
						img: require('@/static/img/7.png')
					},
					{
						name: '全品类数字权益商品，覆盖话费，电费，燃气，水费等数字化标准接口。',
						title: '本地服务场景',
						img: require('@/static/img/6.png')
					},
					{
						name: '集合电商购物，本地生活，生活服务，娱乐影视等1000+权益特权,提供完整的企业福利解决方案。',
						title: '权益特权福利',
						img: require('@/static/img/5.png')
					},
				],
				serviceLst: [{
						name: '一站式对接电商、本地生活、品牌卡券接口，覆盖商品、用户交易等模块。',
						title: '海量接口',
						img: require('@/static/img/9.png')
					},
					{
						name: '提供聚合优惠券商城核心系统能力，大大节省人力和时间双成本。',
						title: '省时省力',
						img: require('@/static/img/10.png')
					},
					{
						name: '技术核心团队,拥有多项完成优秀案例,全服务需求。',
						title: '专业团队',
						img: require('@/static/img/11.png')
					},
					{
						name: '提供定制化需求服务，打造聚合商城，满足特色化经营需求。',
						title: '软件定制开发',
						img: require('@/static/img/12.png')
					},
					{
						name: '商场收益直入客户联盟帐号，数据透明可视，做到有据可依有数可查。',
						title: '收益透明',
						img: require('@/static/img/13.png')
					},
					{
						name: '开方平台全部资源付费客户，在服务周期内可免费持续接入新引入资源。',
						title: '增值服务',
						img: require('@/static/img/14.png')
					},
				],
				advantageLst: [{
						name: '多样化开放业务场景，共建良好的电商行业生态。',
						title: '业务能力开放',
						img: require('@/static/img/16.png')
					},
					{
						name: '高效的对接服务，提供咨询服务号工单等多种渠道的技术支持。',
						title: '高效服务支撑',
						img: require('@/static/img/15.png')
					},
					{
						name: '庞大的用户和商家规模，联合服务市场，实现一站式服务实现。',
						title: '海量用户规模',
						img: require('@/static/img/17.png')
					},
					{
						name: '支持PC端、移动端服务搭建并提供稳健的技术能力。',
						title: '系统能力丰富',
						img: require('@/static/img/18.png')
					},
				],
				SettleLst: [{
						num: '1.',
						title: '注册账户',
						name: '微信扫码登录/注册即可'
					},
					{
						num: '2.',
						title: '创建应用',
						name: '选择相关类型进行创建应用'
					},
					{
						num: '3.',
						title: '选择并调试API',
						name: '使用微客云集开发平台提供的技术测试'
					},
					{
						num: '4.',
						title: '注册账户',
						name: '开发完成在服务市场上发布'
					},
				]
			}
		},
		mounted() {
			this.getShop()
			document.title = '广东顺路信息科技有限公司';
		},
		methods: {
			// 轮播图详情
			swiperDetail() {
				// this.$router.push('/shop')
			},
			// 查看更多商品
			goTypeshop(data, num) {
				// console.log(data)
				// this.$router.push('/shoptype?cid=' + data.id + '&sid=0&classnum=0&titnum=' + num)
				// this.$router.go(0)
				// document.documentElement.scrollTop = 0
			},
			// 入驻
			SettleIn() {
				// this.$emit('settlein')
			},
			// 去平台公告中心
			goAnnouncement() {
				// this.$router.push('/announce')
			},
			getShop() {
				// const self = this
				// axios.get(store.state.url + '/api/pc/get_category_product')
				// 	.then(d => {
				// 		self.shopList = d.data.data.list
				// 		console.log(d.data.data, '成功了')
				// 	})
				// 	.catch(e => {
				// 		console.log(e, '失败了')
				// 	})
			},
		}
	}
</script>

<style lang="scss">
	.home {
		width: 100%;
		height: auto;
		margin-top: 80px;

		>.swiper {
			width: 100%;
			min-width: 1600px;
			height: 440px;
			margin-top: 60px;
			margin: 0 auto;

			.el-carousel__item h3 {
				width: 100%;
				height: 550px;
				color: #ffffff;
				font-size: 58px;
				line-height: 460px;
				margin: 0;
				background-size: 100% 100%;
			}
		}

		.API {
			width: 100%;
			height: 120px;
			background-color: #fff;

			>.center {
				width: 1380px;
				height: 120px;
				margin: 0 auto;
				display: flex;
				justify-content: space-between;

				.list {
					width: 213px;
					height: 120px;

					.name {
						width: 143px;
						height: 44px;
						margin: 34px auto;
						font-size: 16px;
						font-weight: 400;
						color: #1e1e28;
						line-height: 22px;
						overflow-wrap: break-word;
						text-align: left;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						/* 设置行数 */
						overflow: hidden;
					}

					.time {
						width: 153px;
						height: 20px;
						font-size: 14px;
						font-weight: 400;
						color: rgba(0, 0, 0, .45);
						line-height: 20px;
						margin: -25px -11px;
					}

					.qita {
						width: 70px;
						height: 52px;
						color: #0b4ffc;
						line-height: 52px;
						margin: 34px 30px;
						font-size: 14px;
						text-align: left;

						span {
							color: blue;
						}
					}
				}
			}
		}

		.typeshop {
			width: 1380px;
			min-height: 800px;
			margin: 40px auto;

			.shopbox {
				width: 1380px;
				height: 430px;

				.tit {
					width: 100%;
					height: 40px;
					display: flex;
					justify-content: space-between;

					div:nth-child(1) {
						font-size: 22px;
						font-weight: bold;
						text-align: left;
						line-height: 40px;
					}

					div:nth-child(2) {
						width: 50px;
						height: 22px;
						border: 1px solid #999999;
						font-size: 12px;
						text-align: center;
						line-height: 22px;
						color: #818181;
						margin-right: 16px;
						margin-top: 10px;
					}
				}

				.listbox {
					width: 1380px;
					height: 360px;
					display: flex;
					margin-top: 10px;

					.list {
						width: 260px;
						height: 360px;
						background-color: #fff;
						margin-right: 16px;

						img {
							width: 220px;
							height: 220px;
							margin-top: 15px;
						}

						.money {
							width: 220px;
							height: 30px;
							display: flex;
							margin: 5px auto;

							span:nth-child(1) {
								font-size: 22px;
								color: #e50000;
								font-weight: bold;
							}

							span:nth-child(2) {
								font-size: 12px;
								color: #aaa;
								margin: 9px 5px;
								text-decoration: line-through;
							}
						}

						.text {
							width: 220px;
							height: 40px;
							padding-top: 8px;
							font-size: 14px;
							color: #5a5a5a;
							text-align: left;
							overflow-wrap: break-word;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
							/* 设置行数 */
							overflow: hidden;
							margin: -10px auto;
						}

						.history {
							width: 220px;
							height: 25px;
							display: flex;
							justify-content: space-between;
							margin: 20px auto;

							span {
								font-size: 12px;
								color: #aaa;
							}
						}
					}
				}
			}
		}

		.programme {
			width: 1380px;
			height: 604px;
			margin: 80px auto;

			.title {
				font-size: 32px;
				font-weight: 500;
				color: #1e1e28;
				line-height: 45px;
				text-align: center;
			}

			.introduce {
				margin-top: 25px;
				font-size: 20px;
				font-weight: 400;
				color: rgba(0, 0, 0, .45);
				line-height: 28px;
				text-align: center;
			}

			.list {
				width: 100%;
				height: 346px;
				margin: 80px auto;
				display: flex;

				.item {
					width: 320px;
					height: 276px;
					border: 1px solid #ededed;
					background: #fff;
					transition: height .5s;
					display: flex;
					flex-direction: column;
					align-items: center;
					margin-top: 35px;

					.box {
						width: 220px;
						height: 230px;
						margin: 40px 45px;

						.img {
							width: 40px;
							height: 40px;
							// background-color: blue;
							margin: 0 auto;

							img {
								width: 100%;
							}
						}

						.name {
							width: 108px;
							height: 51px;
							padding-top: 20px;
							font-size: 18px;
							color: #000000D9;
							margin: 0 auto;
						}

						.describe {
							width: 220px;
							height: 78px;
							margin-top: 10px;
							font-size: 14px;
							color: #00000073;
							margin: 10px auto;
							line-height: 26px;
						}
					}
				}
			}
		}

		.service {
			width: 1380px;
			height: 722px;
			margin: 0px auto;
			margin-top: -70px;
			background-color: #fff;
			padding: 80px 0px;

			.name {
				width: 1280px;
				height: 45px;
				padding: 0px 0px 15px;
				font-size: 32px;
				color: #1e1e28;
				line-height: 45px;
			}

			.introduce {
				width: 1280px;
				height: 28px;
				color: #00000073;
				font-size: 20px;
				margin: 10px 0px 0px;
			}

			.list {
				width: 1180px;
				height: 624px;
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				flex-wrap: wrap;
				margin: 20px auto;

				.item {
					width: 580px;
					height: 188px;
					border: 1px solid #ebebeb;

					.box {
						width: 502px;
						height: 96px;
						margin: 38px auto;
						display: flex;
						justify-content: space-between;

						.img {
							width: 96px;
							height: 96px;
							// background-color: blue;

							img {
								width: 100%;
							}
						}

						.content {
							width: 386px;
							height: 96px;
							text-align: left;

							p:nth-child(1) {
								width: 386px;
								height: 36px;
								color: #000000D9;
								font-size: 18px;
								line-height: 36px;
							}

							p:nth-child(2) {
								width: 386px;
								height: 52px;
								color: #00000073;
								margin-top: 10px;
								font-size: 14px;
								line-height: 26px;
							}
						}
					}
				}
			}
		}

		.advantage {
			width: 1380px;
			height: 482px;
			padding: 80px 0px;
			margin: 0 auto;

			.advantagename {
				width: 1380px;
				height: 60px;
				color: #1e1e28;
				font-size: 32px;
				padding: 0px 0px 15px;
			}

			.advantageIntroduce {
				width: 1380px;
				height: 28px;
				color: #00000073;
				font-size: 20px;
				margin: 10px 0px 0px;
			}

			.advantageList {
				width: 1380px;
				height: 304px;
				margin: 80px 0px 0px;
				display: flex;
				justify-content: space-evenly;

				.item {
					width: 250px;
					height: 261px;
					background-color: #fff;
					margin: 0px 20px 0px 0px;
					padding: 43px 30px 0px;

					.img {
						width: 88px;
						height: 88px;
						// background-color: blue;
						margin: 0 auto;

						img {
							width: 100%;
						}
					}

					.itemname {
						width: 220px;
						height: 36px;
						color: #000000d9;
						font-size: 18px;
						margin: 15px auto;
						line-height: 36px;
					}

					.itemintroduce {
						width: 220px;
						height: 52px;
						color: #00000073;
						font-size: 14px;
						margin: 10px 0px 0px;
						line-height: 26px;
					}
				}
			}
		}

		.Settle_in {
			width: 100%;
			height: 464px;
			background-color: #fff;
			margin: 0px -77px 0px 0px;
			padding: 80px 0px;

			.inName {
				width: 1380px;
				height: 45px;
				color: #1e1e28;
				font-size: 32px;
				padding: 0px 0px 15px;
				line-height: 45px;
				margin: 0 auto;
			}

			.intype {
				width: 1380px;
				height: 178px;
				margin: 80px 0px 0px;
				margin: 80px auto;

				.item {
					height: auto;
					background-color: #fff;
					margin-right: 40px;
					float: left;
					text-align: left;

					.img {
						width: 60px;
						height: 100px;
						// background-color: blue;
						color: #0b4ffc;
						font-size: 70px;
						line-height: 100px;
						font-weight: bold;
					}

					.name {
						width: auto;
						height: 40px;
						color: #1e1e28;
						font-size: 28px;
					}

					.miaoshu {
						width: auto;
						height: 25px;
						color: #000000a6;
						font-size: 18px;
						margin-top: 15px;
					}
				}
			}

			.inbtn {
				width: 196px;
				height: 46px;
				color: #fff;
				font-size: 15px;
				background-color: #0b4ffc;
				margin: 100px 0px 0px 490px;
				line-height: 46px;
				margin: 0 auto;
			}
		}
	}

	@media screen and (max-width: 900px) {
		.home {
			width: 100%;
			height: auto;
			margin-top: 3.7rem;

			>.swiper {
				width: 100%;
				height: 10rem;
				margin: -3rem auto;

				.el-carousel__item h3 {
					width: 27rem;
					height: 8.5rem;
					color: #ffffff;
					font-size: 58px;
					line-height: 460px;
					margin-top: .5rem;
					background-size: 100% 100%;
				}
			}

			.API {
				width: 100%;
				min-height: 21rem;
				margin-top: 3rem;

				>.center {
					width: 27rem;
					height: 7rem;
					margin: 0 auto;
					display: flex;
					justify-content: space-between;
					flex-wrap: wrap;

					.list {
						width: 13.5rem;
						height: 7rem;
						box-shadow: 1px 1px 1px rgba(194, 194, 194, 0.3);

						.name {
							width: 10rem;
							height: 2.5rem;
							margin: 1.5rem auto;
							font-size: 0.9rem;
							font-weight: 400;
							color: #1e1e28;
							line-height: 1rem;
							overflow-wrap: break-word;
							text-align: left;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
							/* 设置行数 */
							overflow: hidden;
						}

						.time {
							width: 10rem;
							height: 20px;
							font-size: 0.8rem;
							font-weight: 400;
							color: rgba(0, 0, 0, .45);
							line-height: 1.2rem;
							margin: -1rem -1.2rem;
						}

						.qita {
							width: 10rem;
							height: 52px;
							color: #0b4ffc;
							line-height: 52px;
							margin: 2rem 1.8rem;
							font-size: 0.8rem;
							text-align: left;

							span {
								color: blue;
							}
						}
					}
				}
			}

			.typeshop {
				width: 27rem;
				min-height: 40rem;
				margin: 3rem auto;

				.shopbox {
					width: 27rem;
					height: 40rem;
					margin-bottom: 3rem;

					.tit {
						width: 100%;
						height: 2rem;
						display: flex;
						justify-content: space-between;

						div:nth-child(1) {
							font-size: 1rem;
							font-weight: bold;
							text-align: left;
							line-height: 2rem;
							margin-left: 1rem;
						}

						div:nth-child(2) {
							width: 3.1rem;
							height: 1.3rem;
							border: 1px solid #999999;
							font-size: 0.75rem;
							text-align: center;
							line-height: 1.3rem;
							color: #818181;
							margin-right: 1.1rem;
							margin-top: 0.4rem;
						}
					}

					.listbox {
						width: 27rem;
						height: 39.9rem;
						display: flex;
						flex-wrap: wrap;
						margin-top: 0.3rem;
						overflow: hidden;

						.list {
							width: 12rem;
							height: 19.5rem;
							background-color: #fff;
							margin: 0.3rem 0.1rem 0px 0.9rem;

							img {
								width: 10.5rem;
								height: 10.5rem;
								margin-top: 1rem;
							}

							.money {
								width: 11rem;
								height: 2rem;
								display: flex;
								margin: 5px auto;

								span:nth-child(1) {
									font-size: 1.3rem;
									color: #e50000;
									font-weight: bold;
								}

								span:nth-child(2) {
									font-size: 0.75rem;
									color: #aaa;
									margin: 9px 5px;
									text-decoration: line-through;
								}
							}

							.text {
								width: 11rem;
								height: 2rem;
								padding-top: 0.5rem;
								font-size: 0.8rem;
								color: #5a5a5a;
								text-align: left;
								overflow-wrap: break-word;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 2;
								/* 设置行数 */
								overflow: hidden;
								margin: -0.8rem auto;
							}

							.history {
								width: 11rem;
								height: 1.5rem;
								display: flex;
								justify-content: space-between;
								margin: 2.5rem auto;

								span {
									font-size: 0.75rem;
									color: #aaa;
								}
							}
						}
					}
				}
			}

			.programme {
				width: 27rem;
				height: 38rem;
				margin: 5rem auto;
				background-color: #fff;
				padding-top: 2rem;
				margin-bottom: 2rem;

				.title {
					font-size: 1.5rem;
					font-weight: 500;
					color: #1e1e28;
					line-height: 3rem;
					text-align: center;
				}

				.introduce {
					width: 25rem;
					margin: 1rem auto;
					font-size: 1rem;
					font-weight: 400;
					color: rgba(0, 0, 0, .45);
					line-height: 1.75rem;
					text-align: center;
				}

				.list {
					width: 27rem;
					height: 26rem;
					margin: 2rem auto;
					display: flex;
					flex-wrap: wrap;

					.item {
						width: 13.3rem;
						height: 13rem;
						border: 1px solid #ededed;
						background: #fff;
						transition: height .5s;
						display: flex;
						flex-direction: column;
						align-items: center;
						margin-top: 0rem;

						.box {
							width: 13.3rem;
							height: 13rem;
							margin: 0rem 1rem;
							// background-color: red;

							.img {
								width: 2rem;
								height: 2rem;
								margin: 1rem auto;

								img {
									width: 100%;
								}
							}

							.name {
								width: 6.75rem;
								height: 2rem;
								padding-top: 1rem;
								font-size: 18px;
								color: #000000D9;
								margin: 0 auto;
							}

							.describe {
								width: 11rem;
								height: 3rem;
								margin-top: 0.1rem;
								font-size: 0.9rem;
								color: #00000073;
								margin: 0.8rem auto;
								line-height: 1.5rem;
								overflow-wrap: break-word;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 2;
								/* 设置行数 */
								overflow: hidden;
							}
						}
					}
				}
			}

			.service {
				width: 27rem;
				height: 70rem;
				margin: 10rem auto;
				margin-top: 0rem;
				padding: 3rem 0px;

				.name {
					width: 27rem;
					height: 1.5rem;
					padding: -1rem 0px 1rem;
					font-size: 1.2rem;
					color: #1e1e28;
					line-height: 2.8rem;
				}

				.introduce {
					width: 27rem;
					height: 1rem;
					color: #00000073;
					font-size: 1rem;
					margin: 1rem 0rem;
				}

				.list {
					width: 27rem;
					height: 60rem;
					display: flex;
					flex-wrap: wrap;
					margin: 2rem auto;

					.item {
						width: 27rem;
						height: 10.5rem;

						.box {
							width: 27rem;
							height: 6rem;
							margin: 1.5rem auto;
							display: flex;

							.img {
								width: 4rem;
								height: 4rem;
								margin: 1rem;

								img {
									width: 100%;
								}
							}

							.content {
								width: 21rem;
								height: 6rem;

								p:nth-child(1) {
									width: 20rem;
									height: 2rem;
									color: #000000D9;
									font-size: 1.1rem;
									line-height: 2.3rem;
									text-align: left;
								}

								p:nth-child(2) {
									width: 20rem;
									height: 2rem;
									color: #00000073;
									margin-top: 0.5rem;
									font-size: 0.9rem;
									line-height: 1.5rem;
									text-align: left;
								}
							}
						}
					}
				}
			}

			.advantage {
				width: 27rem;
				min-height: 32rem;
				padding: 2rem 0rem;
				margin: 0 auto;

				.advantagename {
					width: 27rem;
					height: 3rem;
					color: #1e1e28;
					font-size: 1.5rem;
					padding: 0px 0px 1rem;
					margin-top: -9rem;
				}

				.advantageIntroduce {
					width: 27rem;
					height: 1rem;
					color: #00000073;
					font-size: 1.25rem;
					margin-top: -1rem;
				}

				.advantageList {
					width: 27rem;
					height: 32rem;
					margin: 3rem 0rem 0rem 0.5rem;
					display: flex;
					flex-wrap: wrap;

					.item {
						width: 8.5rem;
						height: 15rem;
						background-color: #fff;
						margin-top: 0.5rem;

						.img {
							width: 4rem;
							height: 4rem;
							margin: -1rem auto;

							img {
								width: 100%;
							}
						}

						.itemname {
							width: 8rem;
							height: 2rem;
							color: #000000d9;
							font-size: 1rem;
							margin: 1.5rem auto;
							line-height: 2rem;
						}

						.itemintroduce {
							width: 8rem;
							height: 2rem;
							color: #00000073;
							font-size: 0.7rem;
						}
					}
				}
			}

			.Settle_in {
				width: 27rem;
				min-height: 48rem;
				background-color: #f9f9f9;
				margin: 1rem -5rem 0rem 0rem;
				margin-bottom: 25rem;

				.inName {
					width: 27rem;
					height: 2rem;
					color: #1e1e28;
					font-size: 1.5rem;
					padding: 0rem 0rem 1rem;
					margin: 0 auto;
				}

				.intype {
					width: 27rem;
					height: 43rem;
					margin: 1rem auto;

					.item {
						height: 27rem;
						height: 10rem;
						// background-color: #fff;
						margin-top: 0.3rem;

						.img {
							width: 7rem;
							height: 10rem;
							color: #0b4ffc;
							font-size: 4.3rem;
							font-weight: bold;
							float: left;
							line-height: 10rem;
						}

						.name {
							width: 18rem;
							height: 2.5rem;
							color: #1e1e28;
							font-size: 1.5rem;
							float: left;
							margin: -7rem 6rem;
							text-align: left;
						}

						.miaoshu {
							width: 18rem;
							height: 1.5rem;
							color: #000000a6;
							font-size: 1rem;
							margin-top: 1rem;
							float: left;
							margin: -4.7rem 6rem;
							text-align: left;
						}
					}
				}

				.inbtn {
					width: 10rem;
					height: 3rem;
					color: #fff;
					font-size: 1rem;
					background-color: #0b4ffc;
					line-height: 3rem;
					margin: 0 auto;
					display: none;
				}
			}
		}
	}
</style>