<template>
	<div class="developer">
		<div class="top">
			<div class="title">
				<div>开放平台API文档中心</div>
				<div></div>
			</div>
			<div class="search">
				<div></div>
				<div class="inp_bor">
					<div>
						<input type="text" placeholder="输入搜索关键词" />
						<div class="btn">搜索</div>
					</div>
					<div>
						<p>热门搜索:</p>
						<p>点餐</p>
						<p>外卖</p>
						<p>电影票</p>
						<p>肯德基</p>
						<p>花费</p>
					</div>
				</div>
			</div>
		</div>
		<div class="guide">
			<div class="type1">
				<div class="alltype">
					<div :class="totalName?'name color':'name'" @click="ShowType">总类目</div>
					<div class="showType">
						<div v-for="(i,k) in typeList" :key="i" :class="typenum==k?'bgcolor':''" @click="ChangeType(k)">
							{{i}}
						</div>
					</div>
				</div>
				<div class="alone">
					<div class="title">霸王餐API</div>
					<div class="scrollurl">
						<div v-for="(i,k) in 8" :key="k" :class="i=='1'?'item selectBG':'item'">
							<div class="name">美团霸王餐活动列表{{k}}</div>
							<div class="url">rest/Baodan/tasks</div>
						</div>
					</div>
				</div>
			</div>
			<div class="content">
				<div class="detailText">
					<div class="url">rest/Baodan/tasks</div>
					<div class="name">美团霸王餐活动列表</div>
					<div class="timer">
						<span>更新时间:</span>
						<span>2024-05-01</span>
						<span>12:00:00</span>
					</div>
					<div class="free">
						<span><a href="">免费api</a></span>
						<span>|</span>
						<span><a href="">直接调用</a></span>
					</div>
					<div class="direction">获取美团霸王餐的活动列表</div>
				</div>
				<div class="parameter">
					<div class="title">
						公共参数
					</div>
					<div class="tabLst">
						<div class="tabtit">请求参数说明</div>
						<div class="tab">
							<el-table :data="tableData" style="width: 100%;margin-bottom: 20px;" row-key="id" border
								default-expand-all :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
								<el-table-column prop="date" label="参数名称" sortable>
								</el-table-column>
								<el-table-column prop="name" label="参数类型" sortable>
								</el-table-column>
								<el-table-column prop="address" label="是否必填">
								</el-table-column>
								<el-table-column prop="date" label="示例" sortable>
								</el-table-column>
								<el-table-column prop="address" label="参数描述">
								</el-table-column>
							</el-table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				totalName: false,
				typenum: 0,
				tableData: [{
					date: '2016-05-02',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄'
				}, {
					date: '2016-05-04',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1517 弄'
				}, {
					date: '2016-05-01',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1519 弄'
				}, {
					date: '2016-05-03',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1516 弄'
				}],
				typeList: ['霸王api', '数据api', '奈雪api', '必胜客api', '肯德基api', '霸王api2', '数据api2', '奈雪api2', '必胜客api2',
					'肯德基api2'
				],
			}
		},
		mounted() {
			this.SwitchApi()
			document.title = 'API文档-众享数字有限公司';
		},
		methods: {
			SwitchApi() {
				const self = this
				const apis = document.querySelectorAll('.alone >.item')
				apis.forEach((v, k) => {
					if (self.$route.params.id == k) {
						for (var i = 0; i < apis.length; i++) {
							apis[i].setAttribute('class', 'item')
						}
						v.setAttribute('class', 'item selectBG')
					}
					v.onclick = function() {
						for (var i = 0; i < apis.length; i++) {
							apis[i].setAttribute('class', 'item')
						}
						this.setAttribute('class', 'item selectBG')
						self.$router.push('/apis/' + k)
					}
				})
			},
			ShowType() {
				var items = document.querySelector(".alltype >.showType")
				this.totalName = !this.totalName
				if (this.totalName) {
					items.style.height = '22rem'
				} else {
					items.style.height = '0rem'
				}
			},
			ChangeType(num) {
				var items = document.querySelector(".alltype >.showType")
				items.style.height = '0rem'
				this.typenum = num
				this.totalName = false
			},
		}
	}
</script>

<style lang="scss">
	.developer {
		width: 100%;
		height: auto;
		margin-top: 80px;

		.top {
			width: 100%;
			height: 250px;
			padding-bottom: 30px;
			margin-bottom: 20px;

			.title {
				width: 1280px;
				height: 76px;
				margin: 100px auto;

				div:nth-child(1) {
					width: 424px;
					height: 86px;
					line-height: 110px;
					font-size: 44px;
					color: #1e1e28;
					text-align: center;
					margin: 30px auto;
				}
			}

			.search {
				width: 1280px;
				height: 75px;
				margin: 0 auto;
				margin-top: -15px;

				.inp_bor {
					width: 750px;
					height: 75px;
					margin: 55px auto;

					div:nth-child(1) {
						width: 750px;
						height: 48px;
						overflow: hidden;
						position: relative;
						border: 1px solid #ddd;
						border-radius: 5px;
						overflow: hidden;

						input {
							width: 100%;
							height: 48px;
							padding-left: 15px;
							outline: none;
							border: none;
							text-align: left;
						}

						.btn {
							width: 74px;
							height: 52px;
							position: absolute;
							background-color: #0b4ffc;
							font-size: 22px;
							top: -5px;
							right: 0;
							color: #fff;
							line-height: 52px;
							padding-left: 30px;
						}
					}

					div:nth-child(2) {
						width: 750px;
						height: 17px;
						display: flex;
						margin-top: 5px;

						p {
							width: auto;
							height: 17px;
							margin-top: 10px;
							margin: 5px;
							font-size: 12px;
							color: #000000A6;
						}
					}
				}
			}
		}

		.guide {
			width: 1380px;
			min-height: 1000px;
			display: flex;
			justify-content: space-between;
			margin: 10px auto;

			.type1 {
				width: 300px;
				min-height: 1000px;
				overflow: hidden;
				background-color: #fff;
				border-radius: 5px;
				text-align: left;
				position: relative;

				.alltype {
					width: 280px;
					height: auto;
					position: relative;

					.name {
						width: 280px;
						height: 65px;
						padding: 0px 0px 0px 20px;
						font-size: 16px;
						font-weight: bold;
						line-height: 65px;
						border-bottom: 1px solid #dedede;
					}

					>.color {
						color: #0b4ffc;
					}

					.showType {
						width: 300px;
						height: 0px;
						overflow-y: auto;
						position: absolute;
						top: 65px;
						z-index: 1000;
						background-color: #fff;
						transition: height 0.5s;

						div {
							width: 260px;
							height: 50px;
							line-height: 50px;
							font-size: 17px;
							padding: 0px 0px 0px 20px;
						}

						.bgcolor {
							background-color: #0b4ffc;
							color: #fff;
						}
					}
				}

				.alltype:hover .showType {
					height: 450px;
				}

				.alone {
					width: 300px;
					min-height: 350px;
					position: absolute;
					top: 65px;

					.title {
						width: 280px;
						height: 50px;
						padding: 0px 0px 0px 20px;
						line-height: 50px;
						font-size: 15px;
						font-weight: bold;
						color: #1e1e28;
					}

					.scrollurl {
						width: 280px;
						height: auto;

						.item {
							width: 280px;
							height: 55px;
							padding: 0px 0px 0px 20px;
							color: #1e1e28c7;
							background-color: #fff;
							padding-top: 10px;

							.name {
								width: 280px;
								height: 22px;
								font-size: 13px;
								line-height: 22px;
							}

							.url {
								width: 280px;
								height: 22px;
								line-height: 22px;
								font-size: 13px;
							}
						}

						.selectBG {
							background-color: #0b4ffc;
							color: #fff;
						}
					}
				}

			}

			.content {
				width: 1070px;
				min-height: 1000px;
				background-color: #fff;
				border-radius: 5px;

				.detailText {
					width: 950px;
					height: 250px;
					margin: 0 auto;
					text-align: left;
					padding: 50px;

					.url {
						width: 950px;
						height: 52px;
						margin-bottom: 16px;
						font-size: 33px;
						line-height: 52px;
						color: #000;
					}

					.name {
						width: 950px;
						height: 52px;
						font-size: 33px;
						margin-bottom: 16px;
						color: #000;
					}

					.timer {
						width: 950px;
						height: 20px;
						line-height: 20px;
						font-size: 15px;
						color: #000000A6;
						margin: 10px 0px;

						span {
							margin-right: 8px;
						}
					}

					.free {
						width: 950px;
						height: 25px;
						display: flex;
						margin: 16px 0px 0px;
						font-size: 15px;

						span {
							margin-right: 8px;

							a {
								color: #409eff;
							}
						}
					}

					.direction {
						width: 950px;
						height: 50px;
						font-size: 15px;
						color: #000000A6;
						margin-top: 14px;
						border-bottom: 2px solid #e3e3e3;
					}
				}

				.parameter {
					width: 950px;
					min-height: 500px;
					margin: 0 auto;
					text-align: left;

					.title {
						width: 950px;
						height: 50px;
						line-height: 50px;
						font-size: 25px;
					}

					.tabLst {
						width: 950px;
						min-height: 500px;

						.tabtit {
							width: 950px;
							height: 50px;
							font-size: 25px;
							margin: 15px 0px -5px 0px;
						}

						.tab {
							width: 100%;
							min-height: 400px;
						}
					}
				}

			}
		}
	}

	@media screen and (max-width: 900px) {
		.developer {
			width: 27rem;
			height: auto;
			margin-top: 3rem;
			overflow-x: hidden;

			.top {
				width: 27rem;
				height: 20rem;


				.title {
					width: 27rem;
					height: 2rem;
					margin: 3rem auto;
					margin-bottom: 4rem;

					div:nth-child(1) {
						width: 27rem;
						height: 2rem;
						line-height: 5rem;
						font-size: 1.5rem;
						color: #1e1e28;
						text-align: center;
						margin: 1rem auto;
					}
				}

				.search {
					width: 27rem;
					height: 4rem;
					margin: 0 auto;
					margin-top: -1rem;

					.inp_bor {
						width: 20rem;
						height: 2rem;
						margin: 0rem auto;

						div:nth-child(1) {
							width: 20rem;
							height: 2rem;
							overflow: hidden;
							position: relative;
							border: 1px solid #ddd;
							border-radius: 5px;
							overflow: hidden;
							background-color: red;

							input {
								width: 100%;
								height: 2rem;
								padding-left: 1rem;
								outline: none;
								border: none;
								text-align: left;
								font-size: 0.7rem;
							}

							.btn {
								width: 2rem;
								height: 2rem;
								position: absolute;
								background-color: #0b4ffc;
								font-size: 1rem;
								top: -0.3rem;
								right: 0;
								color: #fff;
								line-height: 2rem;
								padding-right: 1.5rem;
							}
						}

						div:nth-child(2) {
							width: 20rem;
							height: 1rem;
							display: flex;

							p {
								width: auto;
								height: 1rem;
								margin-top: 0.6rem;
								margin: 0.3rem;
								font-size: 0.7rem;
								color: #000000A6;
							}
						}
					}
				}
			}

			.guide {
				width: 27rem;
				height: auto;
				display: flex;
				flex-wrap: wrap;
				margin: .5rem auto;

				>.type1 {
					width: 27rem;
					min-height: 28rem;
					background-color: #fff;
					border-radius: 2px;
					text-align: left;
					position: relative;

					.alltype {
						width: 27rem;
						height: auto;
						position: relative;

						.name {
							width: 27rem;
							height: 4rem;
							padding: 0px 0px 0px 0px;
							font-size: 1.2rem;
							font-weight: bold;
							line-height: 4rem;
							border-bottom: 1px solid #dedede;
							color: #000;
						}

						>.color {
							color: #0b4ffc;
						}

						.showType {
							width: 27rem;
							height: 0px;
							overflow-y: auto;
							position: absolute;
							top: 4rem;
							z-index: 1000;
							background-color: #fff;
							transition: height 0.5s;

							div {
								width: 27rem;
								height: 3rem;
								line-height: 3rem;
								font-size: 1rem;
								padding: 0px 0px 0px 0px;
							}

							.bgcolor {
								background-color: #0b4ffc;
								color: #fff;
							}
						}
					}

					.alone {
						width: 27rem;
						min-height: 20rem;
						position: absolute;
						top: 4rem;
						display: flex;
						flex-wrap: wrap;

						.title {
							width: 27rem;
							height: 4rem;
							padding: 0rem 0rem 0rem 0rem;
							line-height: 4.1rem;
							font-size: 1rem;
							font-weight: bold;
							color: #1e1e28;
						}

						.scrollurl {
							width: 27rem;
							height: 16rem;
							overflow-y: auto;
							overflow-x: hidden;

							.item {
								width: 27rem;
								height: 3rem;
								color: #1e1e28c7;
								background-color: #fff;
								display: flex;
								border-bottom: 1px solid #e1e1e1;

								.name {
									width: 13rem;
									height: 3rem;
									font-size: 1rem;
									line-height: 2.5rem;
									text-align: center;
								}

								.url {
									width: 13rem;
									height: 3rem;
									line-height: 2.5rem;
									font-size: 1rem;
								}
							}

							.selectBG {
								background-color: #0b4ffc;
								color: #fff;
							}
						}
					}

				}

				.content {
					width: 27rem;
					height: auto;
					background-color: #fff;
					border-radius: 5px;

					.detailText {
						width: 27rem;
						height: 15rem;
						margin: -3rem auto;

						.url {
							width: 22.5rem;
							height: 3rem;
							margin-bottom: 1rem;
							font-size: 1.5rem;
							line-height: 3rem;
							color: #000;
						}

						.name {
							width: 22.5rem;
							height: 3rem;
							font-size: 2rem;
							margin-bottom: 1rem;
							color: #000;
						}

						.timer {
							width: 15rem;
							height: 1.2rem;
							line-height: 1.2rem;
							font-size: 1rem;
							color: #000000A6;
							margin-left: 3rem;

							span {
								margin-right: .5rem;
							}
						}

						.free {
							width: 15rem;
							height: 1.5rem;
							display: flex;
							margin: 1rem auto;
							font-size: 1rem;

							span {
								margin-right: .5rem;

								a {
									color: #409eff;
								}
							}
						}

						.direction {
							width: 27rem;
							height: 3rem;
							font-size: .9rem;
							color: #000000A6;
							margin-top: .8rem;
							margin-left: -3.2rem;
							border-bottom: 2px solid #e3e3e3;
						}
					}

					.parameter {
						width: 27rem;
						min-height: 30rem;
						margin: 5rem auto;
						text-align: left;

						.title {
							width: 27rem;
							height: 3rem;
							line-height: 3rem;
							font-size: 1.5rem;
							margin-left: 0rem;
						}

						.tabLst {
							width: 24rem;
							min-height: 30rem;

							.tabtit {
								width: 27rem;
								height: 3rem;
								font-size: 1.5rem;
								margin: 1rem 0rem .3rem 0rem;
							}

							.tab {
								width: 26rem;
								min-height: 25rem;
								margin-left: 0.5rem;
							}
						}
					}

				}
			}
		}
	}
</style>